html, body { margin: 0 }
*,
*::before,
*::after {
  box-sizing: border-box;
}
a,
button,
[role="button"],
input,
label,
select,
textarea {
  touch-action: manipulation;
}
