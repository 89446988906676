.mxa, .ma { margin-left: auto }
.mxa, .ma { margin-right: auto }
.mya, .ma { margin-top: auto }
.mya, .ma { margin-bottom: auto; }

.pt0, .py0, .p0 { padding-top: 0 }
.pb0, .py0, .p0 { padding-bottom: 0 }

.mt0, .my0, .m0 { margin-top: 0 }
.mb0, .my0, .m0 { margin-bottom: 0 }

.mt025, .my025, .m025 { margin-top: 0.25em }
.mb025, .my025, .m025 { margin-bottom: 0.25em }
.ml025, .mx025, .m025 { margin-left: 0.25em }
.mr025, .mx025, .m025 { margin-right: 0.25em }
.pt025, .py025, .p025 { padding-top: 0.25em }
.pb025, .py025, .p025 { padding-bottom: 0.25em }
.pl025, .px025, .p025 { padding-left: 0.25em }
.pr025, .px025, .p025 { padding-right: 0.25em }

.mt05, .my05, .m05 { margin-top: 0.5em }
.mb05, .my05, .m05 { margin-bottom: 0.5em }
.ml05, .mx05, .m05 { margin-left: 0.5em }
.mr05, .mx05, .m05 { margin-right: 0.5em }
.pt05, .py05, .p05 { padding-top: 0.5em }
.pb05, .py05, .p05 { padding-bottom: 0.5em }
.pl05, .px05, .p05 { padding-left: 0.5em }
.pr05, .px05, .p05 { padding-right: 0.5em }

.mt075, .my075, .m075 { margin-top: 0.75em }
.mb075, .my075, .m075 { margin-bottom: 0.75em }
.ml075, .mx075, .m075 { margin-left: 0.75em }
.mr075, .mx075, .m075 { margin-right: 0.75em }
.pt075, .py075, .p075 { padding-top: 0.75em }
.pb075, .py075, .p075 { padding-bottom: 0.75em }
.pl075, .px075, .p075 { padding-left: 0.75em }
.pr075, .px075, .p075 { padding-right: 0.75em }

.mt1, .my1, .m1 { margin-top: 1em }
.mb1, .my1, .m1 { margin-bottom: 1em }
.ml1, .mx1, .m1 { margin-left: 1em }
.mr1, .mx1, .m1 { margin-right: 1em }
.pt1, .py1, .p1 { padding-top: 1em }
.pb1, .py1, .p1 { padding-bottom: 1em }
.pl1, .px1, .p1 { padding-left: 1em }
.pr1, .px1, .p1 { padding-right: 1em }
